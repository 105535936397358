import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Row, Spin } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import BillingList from "./BillingLIst";
import { useDispatch, useSelector } from "react-redux";
import { getDashboradListInProgress } from "../../appRedux/admin/DashBorad/action/dashboardAction";
const AdminDashboardScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboradListInProgress());
  }, [dispatch]);
  const { adminDashboard } = useSelector((state) => state);

  const adminDashboardDetails = adminDashboard?.adminDashboardList?.data?.count;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin
      indicator={antIcon}
      spinning={adminDashboard?.adminDashboardList?.progressing}
    >
      <Card>
        <Row gutter={[16, 16]}>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<IdcardOutlined />}
              title={"Over All Patients"}
              subTitle={adminDashboardDetails?.patientCount}
              cardColor={"card-blue"}
              image={patient}
              size={85}
              height={100}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<MedicineBoxOutlined />}
              title={"Over All Doctors"}
              subTitle={adminDashboardDetails?.doctorCount}
              cardColor={"card-orange"}
              className={"admin-dashboard-cards"}
              image={doctor}
              size={75}
              height={100}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<UnorderedListOutlined />}
              title={"Over All Billing"}
              subTitle={adminDashboardDetails?.billingCount}
              cardColor={"card-green"}
              image={billing}
              size={200}
              height={100}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <IconWithTextCard
              icon={<BookOutlined />}
              title={"Today Billing"}
              subTitle={adminDashboardDetails?.todayBillingCount}
              cardColor={"card-violet"}
              image={todayBilling}
              size={80}
              height={100}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="px-0">
          <Col xl={10} lg={10} md={12} sm={12} xs={24}>
            <TicketList
              recentAppointments={adminDashboardDetails?.recentAppointments}
            />
          </Col>
          <Col xl={10} lg={10} md={12} sm={12} xs={24} push={2}>
            <BillingList
              recentAppointments={adminDashboardDetails?.recentBillings}
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default AdminDashboardScreen;
