import { ADD_DOCTOR_LIST_ERROR, ADD_DOCTOR_LIST_PROGRESS, ADD_DOCTOR_LIST_SUCCESS, DELETE_DOCTOR_LIST_ERROR, DELETE_DOCTOR_LIST_PROGRESS, DELETE_DOCTOR_LIST_SUCCESS, DOCTOR_CSV_UPLOAD_IN_FAILURE, DOCTOR_CSV_UPLOAD_IN_PROGRESS, DOCTOR_CSV_UPLOAD_IN_SUCCESS, GET_DOCTOR_LIST_ERROR, GET_DOCTOR_LIST_PROGRESS, GET_DOCTOR_LIST_SUCCESS, GET_DOCTOR_UPDATE_LIST_ERROR, GET_DOCTOR_UPDATE_LIST_PROGRESS, GET_DOCTOR_UPDATE_LIST_SUCCESS, UPDATE_DOCTOR_LIST_ERROR, UPDATE_DOCTOR_LIST_PROGRESS, UPDATE_DOCTOR_LIST_SUCCESS } from "../../../../constants/ActionTypes";

export const getDoctorListInProgress = (payload) => ({
    type: GET_DOCTOR_LIST_PROGRESS,
    payload :payload,

  });

  export const getDoctorListInSuccess = (payload) => ({
    type: GET_DOCTOR_LIST_SUCCESS,
    payload :payload,
  });
  
  export const getDoctorListInError = (payload) => ({
    type:GET_DOCTOR_LIST_ERROR,
    payload :payload,
  });

  export const addDoctorListInProgress = (payload) => (
    {
    type: ADD_DOCTOR_LIST_PROGRESS,
    payload:payload,
  });
  
  export const addDoctorListInSuccess = (payload) => ({
    type: ADD_DOCTOR_LIST_SUCCESS,
    payload:payload,
  });
  
  export const addDoctorListInError = (payload) => ({
    type: ADD_DOCTOR_LIST_ERROR,
    payload:payload,
  });

  export const updateDoctorListInProgress = (payload) => ({
    type: UPDATE_DOCTOR_LIST_PROGRESS,
    payload:payload,
  });
  
  export const updateDoctorListInSuccess = (payload) => ({
    type: UPDATE_DOCTOR_LIST_SUCCESS,
    payload:payload,
  });
  
  export const updateDoctorListInError = () => ({
    type: UPDATE_DOCTOR_LIST_ERROR,
  });

  export const deleteDoctorListInProgress = (payload) => ({
    type: DELETE_DOCTOR_LIST_PROGRESS,
    payload:payload,
  });
  
  export const deleteDoctorListInSuccess = (payload) => ({
    type: DELETE_DOCTOR_LIST_SUCCESS,
    payload:payload,
  });
  
  export const deleteDoctorListInError = (payload) => ({
    type: DELETE_DOCTOR_LIST_ERROR,
    payload:payload,
  });

  export const getDataUpdateInProgress = (payload) => {
    return {
      type: GET_DOCTOR_UPDATE_LIST_PROGRESS,
      payload: payload,
    };
  };
  export const getDataUpdateSuccess = (payload) => {
    return {
      type: GET_DOCTOR_UPDATE_LIST_SUCCESS,
      payload: payload,
    };
  };
  
  export const getDataUpdateError = () => {
    return {
      type: GET_DOCTOR_UPDATE_LIST_ERROR,
    };
  };

  export const doctorCSVUploadInProgress = (payload) => {
    return {
      type: DOCTOR_CSV_UPLOAD_IN_PROGRESS,
      payload: payload,
    };
  };
  export const doctorCSVUploadSuccess = (payload) => {
    return {
      type: DOCTOR_CSV_UPLOAD_IN_SUCCESS,
      payload: payload,
    };
  };
  
  export const doctorCSVUploadError = (payload) => {
    return {
      type: DOCTOR_CSV_UPLOAD_IN_FAILURE,
      payload:payload
    };
  };
