import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  HandleAxiosRequest,
  HandleAxiosResponse,
  HandleAxiosResponseWithError,
} from "./interceptors";

export const axiosConfig = {
  baseURL: `https://api.docmotez.com/prod`,
};

const axiosInstances = axios.create(axiosConfig);

axiosInstances.interceptors.request.use(HandleAxiosRequest, (error) =>
  // Do something with request error
  Promise.reject(error)
);

axiosInstances.interceptors.response.use(
  HandleAxiosResponse,
  HandleAxiosResponseWithError
);

export { axiosInstances };
