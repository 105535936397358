import { Timeline, Typography } from "antd";
import React from "react";
import { viewType } from "./data";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";

function ReferralTimeLine({ data, date }) {
  return (
    <>
      <Typography className="view-name mb-10"> Referrals</Typography>
      {/* {Number(data?.type) === 3 ? ( */}
      <>
        <Timeline onChange={() => {}}>
          <Timeline.Item>
            <Typography className="refermy-5">REFER TO:</Typography>
            <Typography className="my-5">
              {viewType[data?.type]} :
              {data?.refDoctor?.firstName
                ? data?.refDoctor?.firstName
                : data?.RefSubPackageCategory?.package_name}
            </Typography>
            <Typography className="my-5">Reason: {data?.reason}</Typography>
            <Typography className="my-5">Date :{date}</Typography>
            <Typography className="my-5">{data?.message}</Typography>
          </Timeline.Item>
          <Timeline.Item>
            <Typography className="refer">
              {viewType[data?.type]} SUGGESTION:
            </Typography>
            <Typography>
              Report:
              {data?.prescription_id === null ? (
                <>
                  {data?.report_url ? (
                    <Link
                      to={`/pdf-viewer/${encodeURIComponent(data?.report_url)}`}
                      // target="_blank"
                    >
                      REPORT
                    </Link>
                  ) : (
                    "No Precriptions"
                  )}
                </>
              ) : (
                <Link
                  to={{
                    pathname: `${APP_ROUTES.PRESCRIPTIONDATA}`,
                    state: { record: data },
                  }}
                >
                  View Prescription
                </Link>
              )}
            </Typography>
            <Typography className="my-5">Report Date:</Typography>
            <Typography className="my-5">
              Instructions: {data?.remarks}
            </Typography>
          </Timeline.Item>
          <Timeline.Item>
            <Typography className="refer">APPOINTMENT STATUS:</Typography>
            <Typography className="my-5">
              Status :{data?.status_name}
            </Typography>
            <Typography className="my-5">PHR Status</Typography>
          </Timeline.Item>
        </Timeline>
      </>
      {/* ) : (
        <>
          <Timeline onChange={() => {}}>
            <Timeline.Item>
              <Typography className="refer">REFER TO:</Typography>
              <Typography className="my-5">{viewType[data?.type]} :</Typography>
              <Typography className="my-5">Reason: {data?.reason}</Typography>
              <Typography className="my-5">Date :</Typography>
            </Timeline.Item>
            <Timeline.Item>
              <Typography className="refer">SPECIALIST SUGGESTION:</Typography>
              <Typography className="my-5">Report:</Typography>
              <Typography className="my-5">Prescriptions Date:</Typography>
              <Typography className="my-5">Instructions:</Typography>
              <Typography className="my-5">{data?.message}</Typography>
            </Timeline.Item>
            <Timeline.Item>
              <Typography className="refer">APPOINTMENT STATUS:</Typography>
              <Typography className="my-5">Status</Typography>
              <Typography className="my-5">PHR Status</Typography>
            </Timeline.Item>
          </Timeline>
        </>
      )} */}
    </>
  );
}

export default ReferralTimeLine;
