// import React, { useState } from "react";
// import { useSelector } from "react-redux";

// const SearchBox = ({ styleName, placeholder,  patientList,
//   setFilteredData,doctorList,setFilteredDataDoctor }) => {
//     const patientData = useSelector((state)=> state.patient.getList);
//     const getDoctorList = useSelector((state) => state.doctor.getlist);

//   const [searchByFullName, setSearchByFullName] = useState("");
//   const[doctor,setDoctor]=useState("")
//   // const [data, setData] = useState(patientList);

//   const handleData=(event)=>{
//     if(patientData?.success){
//       searchData(event);
//     }
//     else if(getDoctorList?.success){
//       searchDocData(event);
//     }
//   }
//   const handleValue =()=>{
//     if(patientData?.success){
//       p()
//     }
//     else if(getDoctorList?.success){
//       d()
//     }
//   }
//   console.log("searchbox",doctorList)
//   console.log("searchbox2",setFilteredDataDoctor)

//   const searchData = (event) => {
//     const text = event.target.value.toLowerCase();
//     setSearchByFullName(text);
//     const filteredData =  patientList.filter(entry =>
//       entry.firstName.toLowerCase().includes(searchByFullName)||
//       entry.lastName.toLowerCase().includes(searchByFullName)

//     ) ;
//     setFilteredData(filteredData);
//   }
//   const searchDocData = (event)=>{
//     const t = event.target.value.toLowerCase();
//     setDoctor(t);
//     const filteredData =  doctorList.filter(entry =>
//       entry.firstName.toLowerCase().includes(doctor)||
//       entry.lastName.toLowerCase().includes(doctor)
//     ) ;
//     setFilteredDataDoctor(filteredData)
//   }
//   const p =()=>{
//    return searchByFullName
//   }
//   const d = ()=>{
//     return doctor
//   }
//   // // const searchList = searchByFullName
//   //   ? patientList.filter(item =>
//   //       item.firstName.toLowerCase().includes(searchByFullName.toLowerCase()),
//   //     )
//   //   : "";
//     // const test =
//     //   data &&
//     //   data?.filter((entry, key) => {
//     //     return entry[field].toLowerCase().includes(searchValue.toLowerCase());
//     //   });

//     // setFilteredArray(test);
//   // };
//   return (
//     <div className={`gx-search-bar ${styleName}`}>
//       <div className="gx-form-group">

//         <input
//           className="ant-input"
//           type="search"
//           placeholder={placeholder}
//           onChange={handleData}
//           value={handleValue()}
//         />
//         <span className="gx-search-icon gx-pointer">
//           <i className="icon icon-search" />
//         </span>
//       </div>
//     </div>
//   );
// };
// export default SearchBox;

// SearchBox.defaultProps = {
//   styleName: "",
//   value: "",
// };
import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
const SearchBox = ({
  styleName,
  itemList,
  filteredUsers,
  setFilteredUsers,
  field,
  dataSource,
}) => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearchFilter = (e) => {
    setSearchValue(e.target.value);
  };

  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     const filter =
  //       itemList &&
  //       itemList?.filter((entry, key) => {
  //         return entry[field].toLowerCase().includes(search.toLowerCase());
  //       });
  //     setFilteredUsers(filter);
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [searchValue]);
  console.log("field", field, itemList);
  const searchData = (e, itemList, search, field, searchValue) => {
    setSearchValue(e.target.value);
    const searchData = e.target.value;

    if (searchData === "") {
      const test = itemList;

      setFilteredUsers(test);
    } else {
      const test =
        itemList &&
        itemList?.filter((entry, key) => {
          return entry[field].toLowerCase().includes(searchData.toLowerCase());
        });

      setFilteredUsers(test);
    }
  };
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <input
          className="ant-input"
          type="search"
          placeholder="Search "
          value={searchValue}
          onChange={(e) =>
            searchData(e, itemList, searchValue, field, dataSource)
          }
        />
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span>
      </div>
    </div>
  );
};
export default SearchBox;
