import React, { Fragment, useState } from "react";
import { Form, Select, Spin } from "antd";
import _ from "lodash";
const { Option } = Select;
const SelectBoxAppointment = ({
  placeholder = false,
  label,
  options = [],
  margin = "mb-16",
  loading = false,
  name = `testFiled-${Math.random()}`,
  rules = [],
  inverMode = false,
  singleLineMode = false,
  className = "",
  editMode = true,
  colon = false,
  disabled = false,
  onChange = () => {},
  asyncFetchMode = false,
  asynchFuntion,
  searchParamName = "SearchValue",
  defaultParams = {},
  value = undefined,
  accessors = { label: "label", value: "value" },
  CustomOptionsComponent = false,
  allowClear = true,
  hasError = false,
  onClear = false,
  labelCol,
  wrapperCol,
}) => {
  const [dataOptions, setDataOptions] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [searchUsed, setSearchUsed] = useState(false);
  const fetchData = async (search) => {
    try {
      setDataLoading(true);
      const { data } = await asynchFuntion({
        ...defaultParams,
        [searchParamName]: search,
      });
      setDataOptions(data);
      setDataLoading(false);
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        setDataOptions([]);
        setDataLoading(false);
      }
    }
  };
  const handleChange = (val) => {
    try {
      let data = asyncFetchMode ? dataOptions : options;
      onChange(_.find(data, { [accessors.value]: val }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Form.Item
      labelCol={{
        span: labelCol,
      }}
      wrapperCol={{
        span: wrapperCol,
      }}
      className={`${inverMode ? "invert-mode" : ""} ${margin} ${className} ${
        singleLineMode ? "single-line-mode" : ""
      } form-input-container common-select-box-container ${
        !editMode ? "edit-mode-off" : ""
      }`}
      {...(label
        ? {
            label: <span>{label}</span>,
          }
        : {})}
      colon={colon}
      rules={rules}
      {...(!asyncFetchMode ? { name } : {})}
    >
      <Select
        {...(onClear ? { onClear } : {})}
        // bordered={false}
        disabled={disabled}
        loading={asyncFetchMode ? dataLoading : loading}
        placeholder={editMode ? placeholder : ""}
        className={`form-select-box ${
          hasError ? "ant-select-status-error" : ""
        }`}
        showSearch={editMode}
        onChange={handleChange}
        allowClear={allowClear}
        {...(!editMode
          ? {
              open: false,
              allowClear: false,
              clearIcon: false,
              showArrow: false,
            }
          : {})}
        // Async Search Logics
        {...(!asyncFetchMode
          ? {
              optionFilterProp: "children",
              filterOption: (input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase()),
            }
          : { value, filterOption: false, onSearch: (val) => setSearch(val) })}
      >
        <Fragment>
          {!dataLoading ? (
            <>
              {CustomOptionsComponent ? (
                <Fragment>
                  {(asyncFetchMode ? dataOptions : options).map((row, idx) => (
                    <Option value={row[accessors.value]} key={idx}>
                      <CustomOptionsComponent
                        data={row}
                        accessors={accessors}
                        key={idx}
                      />
                    </Option>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {(asyncFetchMode ? dataOptions : options).map((row, idx) => (
                    <Option
                      value={row[accessors.value]}
                      key={idx}
                      disabled={row?.disabled}
                    >
                      {row[accessors.label]}
                    </Option>
                  ))}
                </Fragment>
              )}
            </>
          ) : (
            <Option disabled={true}>
              <div className="d-flex justify-content-center p-20">
                <Spin size="medium" />
              </div>
            </Option>
          )}
          {asyncFetchMode && !searchUsed ? (
            <Option disabled>
              <span className="f-12 text-grey-1">
                Search something to get data...
              </span>
            </Option>
          ) : (
            <Fragment />
          )}
        </Fragment>
      </Select>
    </Form.Item>
  );
};
export default SelectBoxAppointment;
