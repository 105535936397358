import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";

import IntlMessages from "../../../util/IntlMessages";

import moment from "moment";

import CitySelector from "../../Doctor/component/CitySelector";
import StateSelector from "../../Doctor/component/StateSelector";
import CountrySelector from "../../Doctor/component/CountrySelector";

import {
  addTeamMemberInProgress,
  updateTeamMemberInProgress,
} from "../../../appRedux/admin/teamMembers/action/teamMemberAction";
import { getRolesListInProgress } from "../../../appRedux/admin/roles/action/rolesAction";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import SpecializationSelector from "../../Doctor/component/SpecializationSelector";
import DesignationSelector from "../../Doctor/component/DesignationSelector";
import QualificationSelector from "../../Doctor/component/QualificationSelector";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { doctorMode, paymentMode } from "../../../constants/Patient";

const TeamMemberForm = () => {
  const location = useLocation();

  const [dob, setDob] = useState("");
  const [form] = Form.useForm();
  const { teamMember } = useSelector((state) => state);
  const teamMemberLoading = teamMember?.updateTeamMember?.progressing;

  const teamMemberAddLoading = teamMember?.addTeamMember?.progressing;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [photo123, setphoto123] = useState(null);

  const [image, setImage] = useState(null);
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [selectedRole, setSelectedRole] = useState("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [roleId, setRoleId] = useState(2);
  const [defaultroleId, setdefaultRoleId] = useState(2);
  // const  getlist  = useSelector((state) => state.doctor.getlist);
  const { Option } = Select;
  const defaultValues = location?.state;

  const pharmacy_details = defaultValues?.Pharmacy;

  const [number, setNumber] = useState();
  const changeItem = (value) => {
    setNumber(value);
  };
  const { accessRoles } = useSelector((state) => state);
  const roles = accessRoles?.rolesList?.data?.data || [];
  const genderOptions = [
    { label: "Female", value: "F" },
    { label: "Male", value: "M" },
    { label: "Others", value: "O" },
  ];

  useEffect(() => {
    const paylod = {
      pagination_required: "false",
      pageNo: 0,
      pagesize: 0,
    };

    dispatch(getRolesListInProgress(paylod));
  }, []);

  useEffect(() => {
    // Set default values to the form fields
    if (defaultValues) {
      form.setFieldsValue(defaultValues);

      setphoto123(defaultValues?.profile_url);
      if (location.type === "edit") {
        setdefaultRoleId(Number(defaultValues?.User.role_id));
        setSelectedRole(defaultValues?.access_role_id);
        setCountry(defaultValues?.User?.country_code);
        setState(defaultValues?.User?.state);
        setCity(defaultValues?.User?.city);
      }
    }
  }, [defaultValues]);

  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }

  const doctorData = useSelector((state) => state.doctor);
  const doctorSuccess = doctorData?.addlist;

  const dispatch = useDispatch();
  const history = useHistory();
  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.TEAMMEMBERS);
    };
    const formData = {
      ...datas,
      id: defaultValues?.id || "",
      user_id: defaultValues?.User?.id || "",
      firstName: datas.firstName,
      lastName: datas.lastName,
      dob: datas.dob,
      email: datas.email,
      contact: datas.phone,
      address1: datas.address,
      password: datas.password,
      profile_url: image,
      access_role_id: selectedRole,
      gender_type: datas.gender_type,
      role_id: defaultValues?.User?.role_id
        ? defaultValues?.User?.role_id
        : roleId === 0
        ? 5
        : roleId,
    };

    if (location.type === "edit") {
      dispatch(
        updateTeamMemberInProgress({ jsonData: formData, handleSuccess })
      );
    } else {
      dispatch(addTeamMemberInProgress({ jsonData: formData, handleSuccess }));
    }
  };

  const handleDobChange = (event) => {
    const newDob = event.target.value;
    setDob(newDob);
  };
  useEffect(() => {
    if (defaultValues) {
      const initialValues = {
        id: defaultValues.id || "",
        firstName: defaultValues.firstName || "",
        lastName: defaultValues.lastName || "",
        dob: defaultValues.dob || "",
        email: defaultValues.email || "",
        contact: defaultValues.contact || "",
        address1: defaultValues.address1 || "",
        address2: defaultValues.address2 || "",
        city: defaultValues.User.city || "",
        state: defaultValues.User.state || "",
        country_code: defaultValues.User.country_code || "",
        qualification: defaultValues.qualification || "",
        license_no: pharmacy_details?.license_no || "",
        DL_license_no: pharmacy_details?.DL_license_no || "",
        organization_id: defaultValues.organization_id || "",
        specialization_id: defaultValues.specialization_id || "",
        designation_id: defaultValues.designation_id || "",
        gender_type: defaultValues?.gender_type || "",
      };

      form.setFieldsValue(initialValues);

      if (defaultValues.profile_image) {
        setphoto123(defaultValues.profile_image);
      }
    }
  }, [defaultValues, form]);
  const validateDob = () => {
    const currentDate = moment();
    const enteredDate = moment(dob);
    const age = currentDate.diff(enteredDate, "years");

    const minAgeForMinor = 18; // Adjust as needed

    return age < minAgeForMinor;
  };
  const handleGenderChange = (value) => {
    setSelectedGender(value);
  };
  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  useEffect(() => {
    setRoleId(Number(roles.find((val) => val.id === selectedRole)?.role_id));
  
  }, [selectedRole]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Spin
      indicator={antIcon}
      spinning={teamMemberLoading || teamMemberAddLoading}
    >
      <Card>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col className="img my-20">
              {photo123 === null ? (
                <Avatar size={94} icon={<UserOutlined />} />
              ) : (
                <img className="img-btn" src={photo123} />
              )}
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Profile"
                rules={[
                  {
                    required: location.type === "edit" ? false : true,
                    message: "Please upload your image!",
                  },
                ]}
                name="profile_url"
                valuePropName="fileList"
              >
                <Input
                  type={"file"}
                  accept="image/*"
                  name="profile_url"
                  onChange={(e) => encodeImageFileAsURL(e)}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="First Name"
                name="firstName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.firstname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.firstname"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Last Name"
                name="lastName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.lastname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.lastname"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="date"
                placeholder="DOB"
                name="dob"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.dob"} />}
                onChange={handleDobChange}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.dob"} />
                    ),
                  },
                  {
                    validator: validateDob(),
                    message: "Please enter correct age",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.email"} />}
                rules={[
                  {
                    type: "email",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.emailtype"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Contact"
                name="phone"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.contact"} />}
                rules={[
                  {
                    type: "phone",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.contact"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.contact"} />
                    ),
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              />
            </Col>
            {location.type !== "edit" && (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Typography style={{ margin: "10px 0px 6px 0px" }}>
                    * Role
                  </Typography>
                  <Form.Item
                    name="access_role_id"
                    dis
                    rules={[
                      {
                        required: true,
                        message: "Please select a role",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Role"
                      onChange={(value) => setSelectedRole(value)}
                      value={selectedRole}
                      style={{ width: "100%" }}
                    >
                      {roles.map((role) => (
                        <Select.Option key={role.id} value={role.id}>
                          {role.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Gender"
                name="gender_type"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select placeholder="Select gender">
                  {genderOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CountrySelector
                label={"Country Name"}
                name={"country_code"}
                onChange={onChangeCountry}
                country={country}
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StateSelector
                label={"State"}
                name={"state"}
                onChange={onChangeState}
                country={state}
                countryId={country?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CitySelector
                label={"City"}
                name={"city"}
                onChange={onChangeCity}
                country={city}
                StateId={state?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your city!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Address"
                name="address"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.address1"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                ]}
              />
            </Col>
            {roleId === 2 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    placeholder="Address 2"
                    name="address2"
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={<IntlMessages id={"Addnew.doctor.label.address2"} />}
                    rules={[
                      {
                        type: "text",
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.address2"}
                          />
                        ),
                      },
                      {
                        required: false,
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.address2"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <QualificationSelector
                    margin="mb-30"
                    label={"Qualification"}
                    name={"qualification"}
                    placeholder={"Select Qualification"}
                    editMode={true}
                    rules={[
                      {
                        required: true,
                        message: "Please select your qualification",
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    placeholder="License No"
                    name="license_no"
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={
                      <IntlMessages id={"Addnew.doctor.label.license_no"} />
                    }
                    rules={[
                      {
                        type: "text",
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <SpecializationSelector
                    margin="mb-30"
                    label={"Specialization"}
                    name={"specialization_id"}
                    placeholder={"Select Specialization"}
                    editMode={true}
                    rules={[
                      {
                        required: true,
                        message: "Please select your specialization",
                      },
                    ]}
                  />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <DesignationSelector
                    margin="mb-30"
                    label={"Designation"}
                    name={"designation_id"}
                    placeholder={"Select  Designation"}
                    editMode={true}
                    rules={[
                      {
                        required: true,
                        message: "Please select your designation",
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  label={
                    <IntlMessages id={"Addnew.admin.label.DOCTOR.TYPE"} />
                  }
                  name={"doctor_mode"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.admin.validator.Doctormode"}
                        />
                      ),
                    },
                  ]}
                >
                  <FormSelectBox
                    placeholder="Select Doctor mode"
                    // value={frontendValue}
                    options={
                      doctorMode || []
                    }
                    onChange={()=>{}}
                  />
                </Form.Item>
              </Col>
              </>
            )}
            {roleId === 4 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    placeholder="License No"
                    name="license_no"
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={
                      <IntlMessages id={"Addnew.doctor.label.license_no"} />
                    }
                    rules={[
                      {
                        type: "text",
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    placeholder="License No"
                    name="DL_license_no"
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={<IntlMessages id={"DL license No"} />}
                    rules={[
                      {
                        type: "text",
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.doctor.validator.license_no"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
              </>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="password"
                placeholder="Password"
                name="password"
                labelCol={24}
                wrapperCol={24}
                label={<IntlMessages id={"Addnew.doctor.label.password"} />}
                rules={[
                  {
                    required: location.type === "edit" ? false : true,
                    message: "Please enter your password",
                  },
                ]}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                labelCol={24}
                wrapperCol={24}
                label={<IntlMessages id={"Confirm Password"} />}
                rules={[
                  {
                    required: location.type === "edit" ? false : true,
                    message: "Please confirm your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match")
                      );
                    },
                  }),
                ]}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Col>
          </Row>
          {/* <FormInput name="user_id" type="hidden" /> */}
          <Row className="space">
            <Col className="btn-pos">
              <Link to={APP_ROUTES?.TEAMMEMBERS}>
                <ButtonComponent
                  label="Cancel"
                  className={"secondary-color mr-20"}
                />
              </Link>

              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Submit"
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default TeamMemberForm;
