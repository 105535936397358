import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Spin, Tooltip, Typography } from "antd";

import {
  EditFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteBillingInProgress,
  getBillingListInProgress,
  undoBillInProgress,
} from "../../appRedux/doctor/actions/addPayment";

import Message from "../../components/Message/Message";
import CancleModal from "./component/CancleModal";
import ButtonComponent from "../../components/Button/ButtonComponent";
import Auxiliary from "../../util/Auxiliary";
import TableComponent from "../../components/Table/TableComponent";

import {
  deleteSubpackagesInProgress,
  getSubPackagesListInProgress,
} from "../../appRedux/doctor/actions/billingPackage";
import SubPackageModel from "./SubPackageModel";
import { access, formatPrice } from "../../util/utilz";
import { appoinmentType, subPackageType } from "../Doctor/data";

const SubPackageTable = () => {
  const organization_id = localStorage.getItem("org_id");
  const [loading, setLoading] = useState(false);
  const [deleteId, setId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBillingId, setSelectedBillingId] = useState(null);
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const { billingPackages, billingPaymentreducer, deleteSubPackages } =
    useSelector((state) => state);

  const tableData = billingPaymentreducer?.biilingList?.data?.data;
  const totalRecords = billingPackages?.subPackagesList?.data?.totalRecords;
  const subPackageList = billingPackages?.subPackagesList?.data?.data || [];
  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
    const payload = {
      id: deleteId,
    };
    dispatch(deleteSubpackagesInProgress(payload));
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const showDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setId(id);
  };
  const showModal = (record) => {
    setSelectedBillingId(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const paylod = {
      organization_id: organization_id,
      pageNo: 1,
    };
    dispatch(getBillingListInProgress(paylod));
  }, []);
  useEffect(() => {
    dispatch(getSubPackagesListInProgress({ organization_id }));
  }, []);
  const handlePage = (page) => {
    const paylod = {
      organization_id: organization_id,
      pageNo: page,
    };
    dispatch(getSubPackagesListInProgress({ paylod }));
  };
  const handleModalSubmit = () => {
    setIsModalVisible(false);
  };
  const handleDelete = (deleteData) => {
    const handleSuccess = () => {
      const paylod = {
        organization_id: organization_id,
        pageNo: 1,
      };
      dispatch(getSubPackagesListInProgress({ paylod }));
    };
    const payload = {
      id: deleteData.id,
      handleSuccess,
    };
    dispatch(deleteSubpackagesInProgress(payload));
  };
  const handleConformCancle = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = (data) => {
    const payload = {
      id: deleteId,
      reason: data?.reason,
    };
    dispatch(deleteBillingInProgress(payload));

    setNavigate(true);
  };
  const handleUndo = (data) => {
    dispatch(undoBillInProgress(data?.id));
    setNavigate(true);
  };
  const cancel = () => {
    Message.error("Cancelled");
  };
  useEffect(() => {
    if (
      (billingPaymentreducer?.deleteBilling?.success && navigate) ||
      (billingPaymentreducer?.addPayments?.success && navigate) ||
      (billingPaymentreducer?.undo?.success && navigate)
    ) {
      window.location.reload();
      setNavigate(false);
    }
  }, [
    billingPaymentreducer?.deleteBilling?.success && navigate,
    billingPaymentreducer?.addPayments?.success && navigate,
    billingPaymentreducer?.undo?.success && navigate,
  ]);

  const columns = [
    {
      title: "Package Name",
      dataIndex: "package_name",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (val) =>
        appoinmentType?.find((data) => Number(data.value) === Number(val))
          .label,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (data) => formatPrice(data),
    },

    {
      title: "Action",
      dataIndex: "Action",
      width: 150,
      render: (_, record) => {
        const isCancle = tableData?.find((d) => Number(d?.status) === 3);

        return (
          <Row>
            {Number(record?.status) === 3 ? (
              <>
                <Col xs={24}>
                  {" "}
                  <Typography className="cancled-text">
                    This payment was cancelled!.
                  </Typography>
                </Col>
                <div style={{ display: "flex" }}>
                  <Col>
                    <ButtonComponent
                      icon={<UndoOutlined />}
                      size="large"
                      onClick={() => {
                        handleUndo(record);
                      }}
                      label="Undo"
                      style={{ background: "rgb(0,128,0)", color: "white" }}
                    />
                  </Col>
                  <Col span={1}>
                    <Tooltip placement="top" title={record?.reason}>
                      <Button
                        icon={<ExclamationCircleOutlined />}
                        size="large"
                        type="primary"
                      >
                        Reason
                      </Button>
                    </Tooltip>
                  </Col>
                </div>
              </>
            ) : (
              <>
                <Col xs={isCancle ? 5 : 0}></Col>

                <Col xs={isCancle ? 15 : 24}>
                  <Row>
                    {access("billingSubPackage", "edit") && (
                      <Col xs={7}>
                        <Tooltip placement="top" title={"Edit"}>
                          <Typography>
                            {[1, 2].includes(record?.type) && (
                              <ButtonComponent
                                icon={<EditFilled />}
                                size="small"
                                type="primary"
                                onClick={() => {
                                  showModal(record);
                                }}
                              />
                            )}
                          </Typography>
                        </Tooltip>
                      </Col>
                    )}
                    <Col xs={12}>
                      {/* <ButtonComponent
                        icon={<DeleteFilled />}
                        size="small"
                        type="danger"
                        onClick={() => showDeleteModal(record?.id)}
                      /> */}
                    </Col>
                  </Row>
                </Col>
                <Col xs={isCancle ? 5 : 0}></Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    if (
      billingPaymentreducer?.biilingList?.inProgress === true ||
      billingPaymentreducer?.deleteBilling?.inProgress === true
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [billingPaymentreducer]);
  useEffect(() => {
    if (billingPackages?.subPackagesList?.inProgress === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [billingPackages]);

  useEffect(() => {
    if (billingPackages?.deleteSubPackages?.success) {
      dispatch(getSubPackagesListInProgress({ organization_id }));
    }
  }, [billingPackages?.deleteSubPackages]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          type="PackageItems"
          columns={columns}
          dataSource={subPackageList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          documentTitle={"Billing Subpackages"}
        />
        <CancleModal
          isModalOpen={isModalOpen}
          handleConformCancle={handleConformCancle}
          handleSubmit={handleSubmit}
        />
        <SubPackageModel
          isVisible={isModalVisible}
          selectedBillingId={selectedBillingId}
          onCancel={handleCancel}
          type={"edit"}
          onSubmit={handleModalSubmit}
        />
      </Spin>{" "}
      <Modal
        title="Delete Data"
        open={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Auxiliary>
  );
};

export default SubPackageTable;
