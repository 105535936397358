import React, { useEffect } from "react";
import { Card, Col, Progress, Row, Spin, Table } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import { MedicineBoxOutlined, UnorderedListOutlined, IdcardOutlined, BookOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getSuperAdminDashboardInProgress } from "../../appRedux/super-admin/SuperAdminAction";
import DemoArea from "./DemoArea";
import DashboardHeaderSection from "./DashboardHeaderSection";

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const DashboardData = useSelector((state) => state?.superAdmin?.superAdminDashboard);
  const CountData = DashboardData?.data?.count;
  const listData = DashboardData?.data?.count?.organizationsList?.slice(0, 10);
  console.log("listData")

  const organisationColumn = [
    { title: "S.No", dataIndex: "sno", render: (text, record, index) => index + 1 },
    { title: "Organization Serial No", dataIndex: "organization_serial_no", key: "organization_serial_no" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Contact", dataIndex: "contact", key: "contact" },
    { title: "City", dataIndex: "city", key: "city" },
  ];

  useEffect(() => {
    dispatch(getSuperAdminDashboardInProgress());
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 72 }}
      spin
    />
  );

  return (
    <Card>
      {/* <Spin indicator={antIcon} spinning={DashboardData?.inProgress}> */}
     <DashboardHeaderSection/>

        <Row gutter={16} style={{ marginTop: '20px' }}>
  <Col span={16}>
    <Card title="Graph Representation" style={{ height: '100%' }}  headStyle={{ textAlign: 'center',backgroundColor:"#E0F7FA" }}>
      
      <DemoArea/>
    </Card>
  </Col>
  <Col span={8}>
    <Card title="Patient Summary" style={{ height: '100%' }}   headStyle={{ textAlign: 'center' ,backgroundColor:"#F3E5F5",boxShadow:"rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"} }>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col>
          <Progress
  type="circle"
  percent={65}
  strokeColor={{
    '0%': '#1D3557', 
    '100%': '#F72585', 
  }}
  strokeWidth={12}
  trailColor="#F0F0F0"
  width={100}
/>

          </Col>
          <Col>
            <div style={{ textAlign: 'left' }}>
              <div>
                <strong>Total Patient</strong>: 1000
              </div>
              <div style={{ marginTop: '10px' }}>
                <span style={{ color: '#F72585' }}>●</span> Woman: 350
              </div>
              <div>
                <span style={{ color: '#1D3557' }}>●</span> Man: 650
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  </Col>
</Row>

<Table
        showHeader={true}
        columns={organisationColumn}
        dataSource={listData}
        pagination={false}
        scroll={{ x: true }}
      />
      {/* </Spin> */}
    </Card>
  );
};

export default SuperAdminDashboard;
