import React, { useEffect, useState } from "react";
import { getDoctorListEffect } from "../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import SelectBoxAppointment from "./SelectBox";
const DoctorSelectAppointment = ({
  placeholder = "Select Doctor",
  label = "doctor",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
  onChange,
  className,
  disable,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getStatusList();
  }, []);
  const getStatusList = async () => {
    try {
      setLoading(true);
      const { data } = await getDoctorListEffect();
      const doctorList = data?.data;
      let options = doctorList.map((row) => ({
        label: `${row.firstName} - ${row.Doctor_serial_no}`,
        value: row.id,
      }));
      setOptions(options);
      setLoading(false);
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };
  return (
    <SelectBoxAppointment
      loading={loading}
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
      onChange={onChange}
      className={className}
      disabled={disable}
    />
  );
};
export default DoctorSelectAppointment;
