import { all } from "redux-saga/effects";
import patientSaga from "../../admin/patient/saga/patientSaga";
import patientAppointmentSaga from "../../Patient/Sagas/patientAppointmentSaga";
import { DoctorSaga } from "../../admin/Doctor/sagas/doctorSaga";
import { AppointmentSaga } from "./Appointment";
import authSagas from "./Auth";
import DesignationSaga from "./DesignationSaga";
import { HolidaySaga } from "./HolidaySaga";
import { ProfileSaga } from "./Profile";
import { QualificationSaga } from "./QualificationSaga";
import { UsersSaga } from "./User";

import notesSagas from "./Notes";
import OrganisationSaga from "./OrganisationSaga";

import RegisterSaga from "./RegisterSaga";

import SpecializationSaga from "./SpecializationSaga";
import ScheduleSaga from "./ScheduleSaga";
import { prescriptionSaga } from "./PrescriptionSaga";
import DoctorListSagas from "./DoctorListSagas";
import AuthSaga from "./AuthSaga";
import ConsultationSaga from "./consultationSaga";
import TabletSaga from "./TabletsSaga";
import { FamilyMemberSaga } from "./FamilyMembersaga";
import { BillingPackageSaga } from "./BillingPackagesSaga";
import { BillingPaymentSaga } from "./BillingPaymentSaga";
import rolesSaga from "../../admin/roles/saga/rolesSaga";
import teamMemberSaga from "../../admin/teamMembers/teamMemberSaga";

import adminDashboardSaga from "../../admin/DashBorad/dashboradSaga";
import PharmacySaga from "../../Pharmacy/PharmacySaga";
import { DashBoardSaga } from "./DashBoardSaga";
import { AppointmentQuestionsSaga } from "./AppointmentQuestion";
import SuperAdminSaga from "../../super-admin/SuperAdminSaga";
import WardSaga from "../../Ward/wardSaga";
import TrackerSaga from "./TrackerSaga";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    OrganisationSaga(),
    DesignationSaga(),
    SpecializationSaga(),
    patientSaga(),
    DoctorSaga(),
    patientAppointmentSaga(),
    AppointmentSaga(),
    QualificationSaga(),
    ProfileSaga(),
    HolidaySaga(),
    ScheduleSaga(),
    UsersSaga(),
    RegisterSaga(),
    prescriptionSaga(),
    DoctorListSagas(),
    AuthSaga(),
    ConsultationSaga(),
    TabletSaga(),
    FamilyMemberSaga(),
    BillingPackageSaga(),
    BillingPaymentSaga(),
    // pharmacySaga(),
    rolesSaga(),
    teamMemberSaga(),
    PharmacySaga(),
    DashBoardSaga(),
    AppointmentQuestionsSaga(),
    SuperAdminSaga(),
    WardSaga(),
    TrackerSaga(),
    adminDashboardSaga()
  ]);
}
