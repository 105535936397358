import React, { useRef } from "react";
import "./billing.less";
import { Modal, Form, Button, Row, Col, Select, Spin } from "antd";
import { useDispatch } from "react-redux";

import FormInput from "../../components/Input/FormInput";
import { useSelector } from "react-redux";
import {
  addSubpackagesInProgress,
  getSubPackagesListInProgress,
  updateSubpackagesInProgress,
} from "../../appRedux/doctor/actions/billingPackage";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import { appoinmentType, subPackageType } from "../Doctor/data";

const SubPackageModel = ({
  isVisible,
  onCancel,
  onSubmit,
  selectedBillingId,
  type,
  setNavigate,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const organization_id = localStorage.getItem("org_id");

  React.useEffect(() => {
    form.setFieldsValue({
      package_name: type === "edit" ? selectedBillingId?.package_name : "",
      price: type === "edit" ? selectedBillingId?.price : "",
    });
  }, [selectedBillingId]);
  const { billingPaymentreducer } = useSelector((state) => state);
  const handleFinish = () => {
    formRef.current
      .validateFields()
      .then((values) => {
        const payload = {
          ...values,
          id: selectedBillingId?.id,
          handleSuccess: () => {
            const paylod = {
              organization_id: organization_id,
              pageNo: 1,
            };
            dispatch(getSubPackagesListInProgress({ paylod }));
            onCancel();
            form.setFieldsValue({
              package_name: "",
              price: "",
            });
          },
        };

        if (type === "edit") {
          dispatch(updateSubpackagesInProgress(payload));
        } else {
          dispatch(addSubpackagesInProgress(payload));
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  return (
    <Spin spinning={billingPaymentreducer?.addPayments?.inProgress === true}>
      <Modal
        title={type === "edit" ? "Edit Package" : "Add Package"}
        visible={isVisible}
        width={800}
        onCancel={onCancel}
        footer={[
          <Button key="back" className="secondary-color" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleFinish}>
            Submit
          </Button>,
        ]}
        className={isVisible ? "slide-down" : ""}
      >
        <Form form={form} name="collectPaymentForm" ref={formRef}>
          <Row>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"package_name"}
                label={"Package Name"}
                className={"input-alignment"}
                rules={[
                  { required: true, message: "Please enter the Package Name" },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select the Type!",
                  },
                ]}
                label={"Type"}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <FormSelectBox
                  options={subPackageType}
                  placeholder="Select"
                  className="sel"
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="number"
                name={"price"}
                label={"Price"}
                rules={[
                  {
                    required: true,
                    message: "Please enter the Price",
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
};

export default SubPackageModel;
