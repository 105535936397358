import { EditFilled, LoadingOutlined } from "@ant-design/icons";
import React, { Fragment, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import PageHeader from "../../components/PageHeader/PageHeader";
import IntlMessages from "../../util/IntlMessages";
import TableComponent from "../../components/Table/TableComponent";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { formatDate } from "../../util/utilz";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../constants/Routes";
import { getTrackerListByOrganizationsProgress } from "../../appRedux/doctor/actions/Trackers";

const TrackerList = () => {
  const dispatch = useDispatch();
  const { trackerByOrganizations } = useSelector((state) => state?.tracker);
  const columns = [
    {
      title: "Type Name",
      dataIndex: "type_name",
      sortDirections: ["ascend", "descend"],
      key: "type_name",
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sortDirections: ["ascend", "descend"],
      key: "patient_name",
      render: (_, data) => data?.Patient?.firstName,
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sortDirections: ["ascend", "descend"],
      key: "doctor_name",
      render: (_, data) => data?.Doctor?.firstName,
    },

    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (date) => formatDate(date),
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Status",
      dataIndex: "status_name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        console.log("record", record);
        return (
          <>
            {record?.status === 3 ? (
              <Link
                to={`/pdf-viewer/${encodeURIComponent(record?.report_url)}`}
                // target="_blank"
              >
                REPORT
              </Link>
            ) : (
              <Row className="table-button">
                <Col className="button-edit mx-10">
                  <Tooltip placement="top" title={"Add Records"}>
                    <Link
                      to={{
                        pathname: APP_ROUTES.ADDSCAN,
                        state: record,
                      }}
                    >
                      <ButtonComponent type="primary" icon={<EditFilled />} />
                    </Link>
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    previousHandlePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousHandlePage = (page) => {
    dispatch(
      getTrackerListByOrganizationsProgress({
        page,
        organization_id: localStorage.getItem("org_id"),
      })
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  return (
    <Fragment>
      <Spin indicator={antIcon} spinning={trackerByOrganizations?.inProgress}>
        <PageHeader
          header={<IntlMessages id={"sidebar.scan"} />}
          viewButton={false}
        />
        <TableComponent
          columns={columns}
          dataSource={trackerByOrganizations?.data?.data || []}
          pagination={{
            total: trackerByOrganizations?.data?.totalRecords || 1,
            onChange: (page) => previousHandlePage(page),
          }}
          itemList={trackerByOrganizations?.data?.data || []}
          type="Appointment"
          documentTitle={"Appointment"}
        />
      </Spin>
    </Fragment>
  );
};

export default TrackerList;
