import {
  ADD_DOCTOR_LIST_ERROR,
  ADD_DOCTOR_LIST_PROGRESS,
  ADD_DOCTOR_LIST_SUCCESS,
  DELETE_DOCTOR_LIST_ERROR,
  DELETE_DOCTOR_LIST_PROGRESS,
  DELETE_DOCTOR_LIST_SUCCESS,
  DOCTOR_CSV_UPLOAD_IN_FAILURE,
  DOCTOR_CSV_UPLOAD_IN_PROGRESS,
  DOCTOR_CSV_UPLOAD_IN_SUCCESS,
  GET_DOCTOR_LIST_ERROR,
  GET_DOCTOR_LIST_PROGRESS,
  GET_DOCTOR_LIST_SUCCESS,
  GET_DOCTOR_UPDATE_LIST_ERROR,
  GET_DOCTOR_UPDATE_LIST_PROGRESS,
  GET_DOCTOR_UPDATE_LIST_SUCCESS,
  UPDATE_DOCTOR_LIST_ERROR,
  UPDATE_DOCTOR_LIST_PROGRESS,
  UPDATE_DOCTOR_LIST_SUCCESS,
} from "../../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  getlist: { ...defaultStatus },
  addlist: { ...defaultStatus },
  updatelist: { ...defaultStatus },
  deletelist: { ...defaultStatus },
  getupdatelist: { ...defaultStatus },
  getdesignationlist: { ...defaultStatus },
  getorganizationlist: { ...defaultStatus },
  getspecializationlist: { ...defaultStatus },
  csv:{...defaultStatus}
};
const doctorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          progressing: true,
        },
      };
    case GET_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_LIST_ERROR:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_DOCTOR_LIST_ERROR:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_DOCTOR_LIST_ERROR:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case DELETE_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          success: true,
          ...payload,
        },
      };
    case DELETE_DOCTOR_LIST_ERROR:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          error: true,
          ...payload,
        },
      };

    case GET_DOCTOR_UPDATE_LIST_PROGRESS:
      return {
        ...state,
        getupdatelist: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_DOCTOR_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        getupdatelist: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_DOCTOR_UPDATE_LIST_ERROR:
      return {
        ...state,
        getupdatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

      case DOCTOR_CSV_UPLOAD_IN_PROGRESS:
        return {
          ...state,
          csv: { ...defaultStatus, ...payload, progressing: true },
        };
      case DOCTOR_CSV_UPLOAD_IN_SUCCESS:
        return {
          ...state,
          csv: { ...defaultStatus, ...payload.data, success: true },
        };
      case DOCTOR_CSV_UPLOAD_IN_FAILURE:
        return {
          ...state,
          csv: {
            ...defaultStatus,
            ...payload,
            error: true,
          },
        };
    default:
      return state;
  }
};
export default doctorReducer;
