import {  EyeOutlined, LoadingOutlined } from "@ant-design/icons";
  import React, { Fragment, useState } from "react";
  import { Col, Row,Spin, Tooltip  } from "antd";
  import PageHeader from "../../components/PageHeader/PageHeader";

  import IntlMessages from "../../util/IntlMessages";
  import TableComponent from "../../components/Table/TableComponent";

  import { useEffect } from "react";
  import {
    getAppointmentsListInProgress,
    getDoctorAppointmentListInProgress,

  } from "../../appRedux/doctor/actions/Appointment";
  import { useDispatch, useSelector } from "react-redux";


  import moment from "moment";
 
  import {  formatDate } from "../../util/utilz";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../constants/Routes";


  
  const AppoinmentBilling = () => {
    const dispatch = useDispatch();
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
    const doctor_id = localStorage.getItem("doctor_id") !== "null" ? true : false;
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(moment().format("hh:mm A"));
      }, 1000);
      return () => clearInterval(interval);
    }, []);
    const {
      appointmentlist,
      doctorappointmentlist,
    } = useSelector((state) => state.appointment);
    const appointmentLists = doctor_id
      ? doctorappointmentlist?.data?.data
      
      : appointmentlist?.data?.data;
    const totalRecords = doctor_id
      ? doctorappointmentlist?.data?.totalRecords
     
      : appointmentlist?.data?.totalRecords;
    const columns = [
      {
        title: "Patient Name",
        dataIndex: "patient_name",
        sortDirections: ["ascend", "descend"],
        key: "patient_name",
        sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
      },
      {
        title: "Doctor Name",
        dataIndex: "doctor_name",
        sortDirections: ["ascend", "descend"],
        key: "doctor_name",
        sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
      },
      {
        title: "Day",
        dataIndex: "week_day",
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        render: (start_time) => moment(start_time, ["h:mm A"]).format("hh:mm A"),
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        render: (end_time) => moment(end_time, ["h:mm A"]).format("hh:mm A"),
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (date) => formatDate(date),
      },
      {
        title: "Reason",
        dataIndex: "reason",
      },
      {
        title: "Symptom",
        dataIndex: "symptom",
      },
      {
        title: "Status",
        dataIndex: "appointment_status_name",
      },
      {
        title: "Action",
        dataIndex: "Action",
        render: (_, record) => {
          return (
            <>
              <Row className="table-button">
               
              <Col className="button-edit mx-10">
                      <Tooltip placement="top" title={"View"}>
                        <Link
                          to={{
                            pathname: APP_ROUTES.APPOINMENTBILLINGVIEW,
                            state: record,
                          }}
                        >
                          <ButtonComponent
                            type="primary"
                            icon={<EyeOutlined />}
                          />
                        </Link>
                      </Tooltip>
                    </Col>
  
             
              </Row>
            </>
          );
        },
      },
    ];
  
    useEffect(() => {
      previousHandlePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  
    const previousHandlePage = (page) => {
     if (doctor_id) {
        dispatch(
          getDoctorAppointmentListInProgress({ page, currentDate, currentTime })
        );
      } else {
        dispatch(getAppointmentsListInProgress({ page, isPrev: 1 }));
      }
    };
 
    const antIcon = (
      <LoadingOutlined
        style={{
          fontSize: 72,
        }}
        spin
      />
    );
  
    return (
      <Fragment>
        <Spin
          indicator={antIcon}
          spinning={
            appointmentlist?.progressing ||
            doctorappointmentlist?.progressing
          }
        >
          <PageHeader
            header={<IntlMessages id={"Addnew.appoinment.Billings"} />}
            viewButton={false}
          />
              <TableComponent
                columns={columns}
                dataSource={appointmentLists}
                pagination={{
                  total: totalRecords,
                  onChange: (page) => previousHandlePage(page),
                }}
                itemList={appointmentLists}
                type="Appointment"
                documentTitle={"Appointment"}
              />
           

        </Spin>
      </Fragment>
    );
  };

  

export default AppoinmentBilling