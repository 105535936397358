import {
  DeleteOutlined,
  FormOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import React, { Fragment } from "react";
import ButtonComponent from "../../components/Button/ButtonComponent";
import PageHeader from "../../components/PageHeader/PageHeader";
import TableComponent from "../../components/Table/TableComponent";
import IntlMessages from "../../util/IntlMessages";
import { data } from "./data";
import { Row, Col } from "antd";
export const columns = [
  {
    title: "ID",
    dataIndex: "ID",
  },
  {
    title: "Patient",
    dataIndex: "Patient",
  },
  {
    title: "Doctor",
    dataIndex: "Doctor",
  },
  {
    title: "Date-Time",
    dataIndex: "DateTime",
  },
  {
    title: "Remarks",
    dataIndex: "Remarks",
  },
  {
    title: "Status",
    dataIndex: "Status",
  },
  {
    title: "Type",
    dataIndex: "Type",
  },
  {
    title: "Options",
    dataIndex: "Options",
    render: () => {
      return (
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={5}>
            <ButtonComponent
              type="primary"
              icon={<HistoryOutlined />}
            ></ButtonComponent>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={5}>
            <ButtonComponent
              type="primary"
              icon={<FormOutlined />}
            ></ButtonComponent>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={5}>
            <ButtonComponent
              type="primary"
              icon={<DeleteOutlined />}
            ></ButtonComponent>
          </Col>
        </Row>
      );
    },
  },
];
const RequestAppoinments = () => {
  return (
    <Fragment>
      <PageHeader
        header={<IntlMessages id={"Addnew.requestappoinment"} />}
        accessData="false"
      />
      <TableComponent
        columns={columns}
        dataSource={data.filter((person) => person.Status === "requested")}
      />
    </Fragment>
  );
};

export default RequestAppoinments;
