export const AddNewOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Couple", value: "Couple" },
  { label: "Donor", value: "Donor" },
  { label: "Baby", value: "Baby" },
];
export const Blood_Group = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "AB+", value: "AB+" },
  { label: "AB+", value: "AB+" },
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
];
export const Proofs = [
  { label: "Adhar Card", value: "Adhar Card" },
  { label: "Driving Liscense", value: "Driving Liscense" },
  { label: "Voter Id", value: "Voter Id" },
  { label: "PAN Card", value: "PAN Card" },
  { label: "Passport", value: "Passport" },
];
export const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];
export const Table_Row = [
  // value="none" selected disabled hidden>Select an Option</option>
  
  { label: "All", value: "all" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "5", value: "5" },
  { label: "8", value: "8" },
  // { label: "20", value: "20" },
  // { label: "30", value: "30" },
];
export const City = [
  { label: "chennai", value: "chennai" },
  { label: "Salem", value: "Salem" },
  { label: "Erode", value: "Erode" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Trichy", value: "Trichy" },
];
export const State = [
  { label: "Tamil Nadu", value: "tamilnadu" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Punjab", value: "Punjab" },
];

export const Country = [
  { label: "India", value: "India" },
  { label: "Australia", value: "Australia" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Germany", value: "Germany" },
  { label: "Belgium", value: "Belgium" },
];
export const paymentMode = [
  {
    label: "Online", value: 1,
  },
  { label: "Offline", value: 2 },

];
export const doctorMode = [
  {
    label: "Internal", value: 1,
  },
  { label: "External", value: 2 },

];